<template>
  <div id="app">
    <principal-layout/>
    <modal-cupo/>
    <arrow-back-top />
  </div>
</template>
<script>
import ModalCupo from '@/components/ModalCupo.vue'
import PrincipalLayout from '@/components/layouts/PrincipalLayout.vue'
import ArrowBackTop from '@/components/templates/ArrowBackTop.vue'
import { mapState } from 'vuex';
export default {
  components: { ModalCupo, PrincipalLayout, ArrowBackTop },
  computed: {
    ...mapState('globalState', ['tipoLayout'])
  }
}
</script>
<style lang="scss">
  #app {
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
  }
</style>
