<template>
    <div class="container-arrow-back-top" id="arrow-go-top" @click="goTop">
    </div>
</template>
<script>
export default {
    name: 'ArrowBackTop',
    mounted() {
        window.addEventListener('scroll', this.listendScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.listendScroll);
    },
    methods: {
        goTop() {
            window.scrollTo(0,0)
        },
        listendScroll() {
            let element = document.getElementById('arrow-go-top')
            if(window.scrollY > window.innerHeight && element) {
                element.style.display = 'flex'
            } else if (element) {
                element.style.display = 'none'
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    .container-arrow-back-top {
        display: none;
        position: fixed;
        z-index: 100;
        bottom: 1rem;
        right: 1rem;
        background-color: #FFD947;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s linear;
        &::after {
            content: '';
            background-image: url('../../assets/icons/icon-arrow-top.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 16px;
            height: 24px;
            top: calc(50% - 12px);
            left: calc(50% - 8px);
        }
    }
</style>