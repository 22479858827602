<template>
    <section class="section-menu" id="section-menu">
        <header class="header-menu">
            <p>
                ¿Estas buscando una manera fácil y segura de financiar tus compras? ¡Entonces tienes que conocer a Quac!
            </p>
        </header>
        <section class="section-destacados">
            <a href="https://clientes.quac.co/" target="_blank">Iniciar sesión</a>
            <button class="buton buton-outline pill-badges" @click="openLink('/solicitud')">
                Solicita tu cupo
            </button>
            <button class="buton buton-primary pill-badges" @click="openLink('https://portalpagos.quac.co/')">
                Paga tu cuota
            </button>
        </section>
        <nav class="nav-menu">
            <router-link :to="{name: 'Home'}">
                <img src="@/assets/logo-quac-morado.svg" class="icon-logo" alt="Logo Quac">
            </router-link>
            <div class="section-nav" v-if="$route.name == 'Home'">
                <p class="router-link-active" @click="navTo('section-how-found')">¿Cómo funciona?</p>
                <p class="router-link-active" @click="navTo('section-benefits')">Beneficios</p>
                <p class="router-link-active" @click="navTo('section-aliados-quac')">Comercios aliados</p>
                <p class="router-link-active" @click="navTo('section-download-app')">Descarga la app</p>
            </div>
            <button class="btn-nav-movil" @click="toggleModal">
                <img src="@/assets/icon-nav-movil.svg">
            </button>
        </nav>
        <div class="sidebar-content" id="sidebar-movil">
            <header>
                <button class="btn-close-sidebar" @click="toggleModal">
                    <img src="@/assets/close-sidebar.svg">
                </button>
                <h3 class="title-section">¿Aún no tienes cupo?</h3>
                <button class="buton buton-outline" @mouseover="hoverBTN" @mouseleave="hoverBTN" @click="openLink('/solicitud')">
                    Solicitar cupo
                </button>
            </header>
            <div class="content-menu">
                <button class="buton buton-outline mb-3" @mouseover="hoverBTN" @mouseleave="hoverBTN" @click="openLink('https://clientes.quac.co/')">
                    Iniciar sesión
                </button>
                <button class="buton buton-primary mb-5" id="btndestacado" @click="openLink('https://portalpagos.quac.co/')">
                    Paga tu cuota
                </button>
                <button class="buton buton-menu" @click="navTo('section-what-is')">
                    <span class="router-link-active">¿Qué es Quac?</span>
                    <img src="@/assets/icon-row-right.svg">
                </button>
                <button class="buton buton-menu" @click="navTo('section-how-found')">
                    <span class="router-link-active">¿Cómo funciona?</span>
                    <img src="@/assets/icon-row-right.svg">
                </button>
                <button class="buton buton-menu" @click="navTo('section-benefits')">
                    <span class="router-link-active">Beneficios</span>
                    <img src="@/assets/icon-row-right.svg">
                </button>
                <button class="buton buton-menu" @click="navTo('section-aliados-quac')">
                    <span class="router-link-active">Comercios aliados</span>
                    <img src="@/assets/icon-row-right.svg">
                </button>
                <button class="buton buton-menu" @click="navTo('section-download-app')">
                    <span class="router-link-active">Descarga la app</span>
                    <img src="@/assets/icon-row-right.svg">
                </button>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            openSidebar: false,
            prevScrollpos: 0,
        }
    },
    mounted() {
        document.addEventListener('scroll', this.listeningScrol)
    },
    unmounted() {
        document.removeEventListener('scroll', this.listeningScrol)
    },
    methods: {
        toggleModal() {
            const sidebar = document.getElementById('sidebar-movil')
            if (!sidebar) return
            sidebar.classList.toggle('open')
        },
        hoverBTN() {
            const btndestacado = document.getElementById('btndestacado')
            if (!btndestacado) return
            btndestacado.classList.toggle('buton-primary')
            btndestacado.classList.toggle('buton-outline')
        },
        navTo(nav) {
            const element = document.getElementById(nav)
            if (!element) return
            window.scrollTo(0, element.offsetTop)
            this.toggleModal()
        },
        listeningScrol() {
            const menu = document.getElementById('section-menu');
            const scrollY = window.scrollY;
            const heightNav = menu.offsetHeight - 80
            if (heightNav <= scrollY && this.prevScrollpos > scrollY){
                menu.style.top = `-${heightNav}px`
            } else {
                menu.style.top = `-${menu.offsetHeight}px`
            }
            this.prevScrollpos = scrollY
        },
        openLink(link) {
            location.href = link
        },
    },
}
</script>
<style lang="scss" scoped>
    .section-menu {
        position: sticky;
        z-index: 100;
        transition: all .2s linear;
        .header-menu {
            background: var(--gradientSecundary);
            padding: .5rem 1rem;
            p {
                color: var(--textWhite);
                text-align: center;
                font-size: var(--fontText);
                margin-bottom: 0;
            }
        }
        .section-destacados {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1rem 1.5rem;
            background-color: #F0ECF9;
            gap: 15px;
        }
        .nav-menu {
            min-height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: .5rem 1.5rem;
            background-color: var(--bgColor);
            .icon-logo {
                width: 132px;
            }
            .section-nav {
                display: flex;
                align-items: center;
                gap: 15px;
            }
            .btn-nav-movil {
                display: none;
            }
        }
        .sidebar-content {
            background-color: white;
            min-height: 100dvh;
            width: 100%;
            display: none;
            position: fixed;
            top: 0;
            left: -100%;
            transition: all .2s linear;
            &.open {
                left: 0 !important;
            }
            header {
                padding: 2rem 1.5rem;
                background-color: #F4F2F9;
                justify-content: center;
                display: grid;
                grid-template-columns: 1fr;
                text-align: center;
                h3 {
                    font-weight: bold;
                    color: #2E1380;
                    font-weight: bold;
                }
                .buton-outline {
                    font-weight: bold;
                }
                .btn-close-sidebar {
                    margin-left: auto;
                    background-color: transparent;
                    border: none;
                    margin-bottom: 1rem;
                }
            }
            .content-menu {
                padding: 2rem 1.5rem;
                display: grid;
                grid-template-columns: 1fr;
                .buton-outline {
                    font-weight: bold;
                }
                .buton-primary:hover {
                    background-color: white;
                }
                .buton-menu {
                    display: flex;
                    justify-content: space-between;
                    &:last-child {
                        border-bottom: 1px solid #B4A9CA;
                    }
                }
            }
        }
        @media (max-width: 700px) {
            .section-destacados {
                display: none;
            }
            .nav-menu {
                .section-nav {
                    display: none;
                }
                .btn-nav-movil {
                    display: flex;
                    background-color: transparent;
                    border: none;
                }
            }
            .sidebar-content {
                display: block;
            }
        }
    }
</style>